import React from 'react'
import { navigate } from 'gatsby'
import { v4 as uuid } from 'uuid'
import * as axios from 'axios'

import { Question, QAPanel, Answer, value } from '@karakuri-faq/component'

import { AnswerWidgetProps } from '../widgets/interface'
import { Session } from '../session'

import { NaviWidget } from './NaviWidget'
import { feedback } from '../utils/gtm'

export const AnswerWidget: React.FC<AnswerWidgetProps> = ({ data, pathContext, breadcrumb, skillApi }) => {
  const { ask, contents, actions } = data.answerYaml
  const { questionnaire } = data.settingYaml
  const { answers, cardId, operatorEscalationKeyword, karakuriWebRootUrl, info } = pathContext

  const handleClick = (path: string) => {
    navigate(path, {
      state: {
        hierarchies: [...breadcrumb.hierarchies, { title: pathContext.ask, link: location.pathname }]
      }
    })
  }
  if (!contents) {
    return null
  }
  function feedbackAction(action: 'yes' | 'no', callback: Function) {
    const url = `${karakuriWebRootUrl}/api/chats`
    const feedbackString = action === 'yes' ? 'ok' : 'ng'
    const session = Session.value || uuid()
    const body = { session, referrer: location.href, query: { card: cardId, feedback: feedbackString } }
    axios.default
      .post(url, body)
      .catch(() => null)
      .then(() => {
        callback()
        feedback(cardId, feedbackString)
      })
  }
  return (
    <QAPanel className="faq-answer">
      <Question q={ask || ''} info={info} />
      <Answer
        contents={contents as any}
        actions={actions as any}
        onClick={handleClick}
        cardId={cardId}
        answers={answers}
        operatorEscalationKeyword={operatorEscalationKeyword}
        karakuriWebRootUrl={karakuriWebRootUrl}
        feedbackYes={(questionnaire && questionnaire.yes) || ''}
        feedbackNo={(questionnaire && questionnaire.no) || ''}
        info={info}
        session={Session}
        onFeedback={feedbackAction}
        skillApi={skillApi}
      />
    </QAPanel>
  )
}

export function renderWidgets(types: string[], props: AnswerWidgetProps) {
  return types.map(type => {
    switch (type) {
      case 'navi':
        return (
          <NaviWidget
            key={type}
            botId={value(props.data.settingYaml.botId, '')}
            breadcrumb={props.breadcrumb}
            tagCloud={props.data.settingYaml.tagCloud}
            relatedCategories={props.pathContext.relatedCategories}
          />
        )
      case 'answer':
        return <AnswerWidget key={type} {...props} />

      default:
        return null
    }
  })
}
