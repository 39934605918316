import * as React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'

import { Page, faqPage, SkillApi } from '@karakuri-faq/component'
import IndexLayout from '../layouts'

import { SEOWidget as SEO } from '../widgets/SEOWidget'
import { AnswerData } from '../widgets/interface'
import { renderWidgets } from '../widgets/AnswerWidget'
import { SitePageContext } from '../graphql'

function headerTitle(subCategoryName: string[], categoryName: string[], title: string, ask: string) {
  if (subCategoryName) {
    return decodeURIComponent(subCategoryName[1]) || ask
  } else if (categoryName) {
    return decodeURIComponent(categoryName[1]) || ask
  }
  return title || ask
}
interface AnswerPageProps {
  data: AnswerData
  pathContext: SitePageContext
  location: any
}
const AnswerPage: React.FC<AnswerPageProps> = ({ data, pathContext, location }) => {
  const { ask, contents } = data.answerYaml
  const { url, tagCloud } = data.settingYaml
  const { parentTitle, parentHasAnswers, parentId, id, title } = pathContext
  const categoryId = location.search.match(/(?:\?|&)category_id=([^&]+?)(&|$)/)
  const categoryName = location.search.match(/(?:\?|&)category_name=([^&]+?)(&|$)/)
  const subCategoryId = location.search.match(/(?:\?|&)sub_category_id=([^&]+?)(&|$)/)
  const subCategoryName = location.search.match(/(?:\?|&)sub_category_name=([^&]+?)(&|$)/)
  const parent = parentTitle
    ? {
        title: categoryName ? decodeURIComponent(categoryName[1]) : parentTitle,
        link: parentHasAnswers
          ? `/category/${categoryId ? decodeURIComponent(categoryId[1]) : parentId}`
          : `/#${categoryId ? decodeURIComponent(categoryId[1]) : parentId}`
      }
    : null
  const sub =
    subCategoryId && subCategoryName
      ? { title: decodeURIComponent(subCategoryName[1]), link: `/category/${decodeURIComponent(subCategoryId[1])}` }
      : id
      ? { title, link: `/category/${id}` }
      : null
  const breadcrumb = {
    current: pathContext.ask || '',
    url: url || '',
    hierarchies: location.state && location.state.hierarchies ? location.state.hierarchies : [parent, sub].filter(x => !!x)
  }
  const skillApi: SkillApi = {
    baseUrl: '',
    token: '',
    isAvailableSkill: () => false
  }
  const widgetProps = { data, breadcrumb, tagCloud, pathContext, skillApi }

  return (
    <IndexLayout>
      <SEO pathname={location.pathname} title={pathContext.ask || ''} />
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(faqPage(ask, contents))}</script>
      </Helmet>
      <Page className="faq-content faq-answer-page">
        <div className="faq-main-content">
          <div className="faq-header">
            <h2>{headerTitle(subCategoryName, categoryName, title || '', pathContext.ask || '')}</h2>
          </div>
          {renderWidgets(['navi', 'answer'], widgetProps)}
        </div>

        <div className="faq-left-content faq-no-content">{renderWidgets([], widgetProps)}</div>
        <div className="faq-right-content faq-no-content">{renderWidgets([], widgetProps)}</div>
      </Page>
    </IndexLayout>
  )
}

export default AnswerPage

export const query = graphql`
  query AnswerQuery($answerId: String) {
    answerYaml(id: { eq: $answerId }, ignore: { ne: true }) {
      id
      ask
      contents {
        text
        contentFormat
        image
      }
      actions {
        # image
        actions {
          id
          label
        }
      }
    }
    settingYaml(ignore: { ne: true }) {
      botId
      url
      tagCloud
      questionnaire {
        yes
        no
      }
    }
  }
`
